<template>
  <div>
    <div class="title">
      <div class="title_t" style="margin-bottom: 0;">
        <span>薪酬记录</span>
      </div>
      <!--<div class="title_r" @click="returnPage">-->
      <!--  <span>返回</span>-->
      <!--</div>-->
    </div>

    <el-form ref="form" :model="form" label-width="140px">
      <el-card shadow="never">
        <el-row :gutter="15" style="margin-bottom: 15px;margin-top: 15px;">
          <el-col :span="6">
            <el-form-item label="姓名：">
              <el-input
                clearable
                v-model="form.name"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="周期：">
              <el-date-picker
                v-model="form.period"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="月度营业额提成：">
              <el-input
                clearable
                v-model="form.month_turnover"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="月度利润提成：">
              <el-input
                clearable
                v-model="form.month_profit"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="6">
            <el-form-item label="考核奖罚：">
              <el-input
                clearable
                v-model="form.profit_reward"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="无投诉奖励：">
              <el-input
                clearable
                v-model="form.no_complaint"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="无催单奖励：">
              <el-input
                clearable
                v-model="form.no_reminder"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合格率奖励：">
              <el-input
                clearable
                v-model="form.yield"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-bottom: 15px;margin-top: 15px;">
          <el-col :span="6">
            <el-form-item label="合格薪资：">
              <el-input
                clearable
                v-model="form.qualified"
                placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="background: #f7f8fa;border: 1px solid #eeeeee;border-radius: 10px;padding: 20px;padding-bottom: 0;">
          <el-row :gutter="15">
            <el-col :span="6">
              <el-form-item label="基本工资：">
                <el-input
                  clearable
                  v-model="form.base_pay"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="岗位工资：">
                <el-input
                  clearable
                  v-model="form.post_wage"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="利润奖金：">
                <el-input
                  clearable
                  v-model="form.profit_bonus"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目工资：">
                <el-input
                  clearable
                  v-model="form.project_wage"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="6">
              <el-form-item label="班次补助：">
                <el-input
                  clearable
                  v-model="form.shift_allowance"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="全勤工资：">
                <el-input
                  clearable
                  v-model="form.full_attendance"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目奖金：">
                <el-input
                  clearable
                  v-model="form.project_funds"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="月度奖金：">
                <el-input
                  clearable
                  v-model="form.month_bonus"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="6">
              <el-form-item label="取消薪资：">
                <el-input
                  clearable
                  v-model="form.feifeiture_payment"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="夜班全勤：">
                <el-input
                  clearable
                  v-model="form.full_night_shift"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="夜班奖励：">
                <el-input
                  clearable
                  v-model="form.night_shift_bonus"
                  placeholder="请输入"/>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-row style="margin-top: 20px;">
          <span style="font-size: 18px;font-weight: 500;color: #202033;margin-right: 20px;">合计应付工资：</span>
          <span style="font-size: 30px;font-weight: 700;color: #fea837;">
            <el-input
              clearable
              v-model="form.total"
              placeholder="请输入"/>
          </span>
        </el-row>

      </el-card>


    </el-form>

    <!--按钮-->
    <div class="card_bottom">
      <el-button style="width: 200px !important;" @click="cancellation()">取消</el-button>
      <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save">提交</el-button>
    </div>

  </div>
</template>

<script>
import {Addemolument_save} from "@/api/yunli";

export default {
  name: 'index',
  // 支柱道具属性
  props: {

  },
  // 组件生效
  components: {},
  // Data数据
  data() {
    return {
      form:{

      }
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {

  },
  // 方法集合
  methods: {
    //返回按钮
    returnPage(){
      this.$emit('showPay',false) ;
    },
    //取消按钮
    cancellation(){
      this.$emit('showPay',false) ;
    },

    //提交
    save(){
      Addemolument_save(this.form).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.form = {};
          this.$emit('showPay',false) ;
        }else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.$message.error(err.msg)
      })
    }


  },
}
</script>

<style scoped lang="scss">
.span_l{
  display: inline-block;
  width: 128px;
  text-align: right;
}
.span_r{
  font-size: 15px !important;
}
</style>
